import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { get, merge, set, clone } from "lodash";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import styled from "styled-components";

import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";
import { ContentKeyToLabel } from "@/components/Settings/email/emailSettingsConstants";

const ContentContainer = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
`;

type CheckboxGroupControllerProps = PropsWithChildren<{
  groupKey: string;
  childrenContentKey: string;
}>;

export const CheckboxGroupController: React.FC<
  CheckboxGroupControllerProps
> = ({ groupKey, childrenContentKey, children }) => {
  const { setConfigurationData, content } = useEmailSettingsContext();
  const value: boolean = get(content, groupKey) ?? false;
  const label: string = get(ContentKeyToLabel, groupKey) ?? "";
  const childrenValues: boolean[] = Object.values(
    get(content, childrenContentKey)
  );

  const isIndeterminate = useMemo(() => {
    return (
      childrenValues.some((value) => value) &&
      childrenValues.some((value) => !value)
    );
  }, [childrenValues]);

  const getAreAllOptionsEnabled = useCallback(() => {
    return childrenValues.every((value) => value);
  }, [childrenValues]);

  useEffect(() => {
    const allEnabled = getAreAllOptionsEnabled();
    if (allEnabled !== value) {
      const newEnabledState = set(clone(content), groupKey, allEnabled);
      setConfigurationData({
        content: merge(content, newEnabledState),
      });
    }
  }, [content, groupKey, getAreAllOptionsEnabled, setConfigurationData, value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    const currentChildrenState: boolean[] = get(content, childrenContentKey);

    const newChildrenState = Object.keys(currentChildrenState).reduce(
      (acc, key) => ({ ...acc, [key]: newValue }),
      {}
    );
    const newContent = set(
      clone(content),
      childrenContentKey,
      newChildrenState
    );
    const newEnabledState = set(clone(content), groupKey, newValue);

    setConfigurationData({
      content: merge(newContent, newEnabledState),
    });
  };

  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            checked={value}
            onChange={onChange}
            indeterminate={isIndeterminate}
          />
        }
      />
      <ContentContainer>{children}</ContentContainer>
    </FormGroup>
  );
};
