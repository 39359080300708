import React from "react";
import styled from "styled-components";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import { useGetCertificateData } from "./useGetCertificateData";
import {
  useCertificateRelatedResourceColumns,
  useCertificateRelatedResourceRows,
} from "./CertificateRelatedResourceTableHooks";

import { CertificateExpirationData } from "@/generated/reliabilityApi";

const StyledDataGrid = styled(DataGridPro)`
  && {
    background-color: #fff;
  }
`;

const Container = styled.div`
  padding: 16px;
`;

interface Props {
  certificateData: CertificateExpirationData;
}

export const CertificateRelatedResourcesTable: React.FC<Props> = ({
  certificateData,
}) => {
  const { certificateUid, certificateName } = certificateData;
  const { data } = useGetCertificateData(certificateUid, certificateName);

  const columns = useCertificateRelatedResourceColumns();
  const rows = useCertificateRelatedResourceRows(
    data?.data.object.relatedResources
  );

  return (
    <Container>
      <StyledDataGrid
        rows={rows}
        columns={columns as GridColDef<GridValidRowModel>[]}
        hideFooter
      />
    </Container>
  );
};
