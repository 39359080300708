import React, { useRef } from "react";
import styled from "styled-components";

import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { DrawerBase } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/DrawerBase";

const IframeContainer = styled.div`
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const SyntheticViolation: React.FC = () => {
  const { data } = useViolation();
  const { headerProps, summaryProps } = useGetCommonDrawerProps(
    data?.data.violation
  );

  const iframeRef = useRef<HTMLIFrameElement>(null);

  if (!data?.data.violation) return null;

  const title = data.data.violation.supportingData.syntheticData?.title ?? "";
  const iframeSrc =
    data.data.violation.supportingData.syntheticData?.drawerHTML;

  return (
    <DrawerBase
      header={{ ...headerProps, title }}
      summary={{ data: summaryProps }}
      content={
        <IframeContainer>
          <iframe src={iframeSrc} ref={iframeRef} />
        </IframeContainer>
      }
    />
  );
};
