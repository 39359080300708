import { useMemo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import {
  CertificateFullRelatedResources,
  CertificateRelatedResource,
  StatusCondition,
} from "@/generated/addonsApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ResourceStatus } from "@/components/k8sAddons/LiveStateDrawer/components/ResourceLink";

const filterUnwantedResources = (value: [string, unknown]) => {
  const resourceKey = value[0] as keyof CertificateFullRelatedResources;
  const resourceValue = value[1] as CertificateRelatedResource;
  return resourceKey !== "workloads" && resourceValue.komodorUid;
};

type CertificateRelatedResourceRow = {
  id: number;
  kind: keyof CertificateFullRelatedResources;
  name: string;
  status: StatusCondition | undefined;
};

export const useCertificateRelatedResourceRows = (
  relatedResources: CertificateFullRelatedResources | undefined
) => {
  return useMemo<CertificateRelatedResourceRow[]>(() => {
    if (!relatedResources) return [];

    return Object.entries(relatedResources)
      .filter(filterUnwantedResources)
      .map(([key, value], index) => {
        const resourceData = value as CertificateRelatedResource;
        const { name = "" } = parseKomodorUid(resourceData.komodorUid) ?? {};

        return {
          id: index,
          kind: key as keyof CertificateFullRelatedResources,
          name,
          status: resourceData.status,
        };
      });
  }, [relatedResources]);
};

export const useCertificateRelatedResourceColumns = () => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "kind",
      headerName: "Kind",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<CertificateRelatedResourceRow>
      ) => {
        return <ResourceStatus status={params.value} />;
      },
    },
  ];
};
