import React, { ComponentProps, useMemo } from "react";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "@komodorio/design-system/komodor-ui";
import { get, set } from "lodash";

import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";

type MultiSelectControllerProps = {
  options: MuiSelectionOption<string>[];
  contextKey: string;
  selectProps?: Omit<ComponentProps<typeof AdvancedMultiSelect>, "options">;
};

export const MultiSelectController: React.FC<MultiSelectControllerProps> = ({
  contextKey,
  options,
  selectProps,
}) => {
  const { setConfigurationData, ...context } = useEmailSettingsContext();
  const value: string[] = useMemo(
    () => get(context, contextKey) ?? [],
    [context, contextKey]
  );

  const valueAsOptions = useMemo(
    () =>
      value.map((cluster) => ({
        value: cluster,
        label: cluster,
      })) ?? [],
    [value]
  );
  const onSelectChange = (
    selectedOptions: MuiSelectionOption<MuiSelectionOptionValue>[]
  ) => {
    const optionsAsStrings = selectedOptions.map((option) => option.value);
    const newData = set(context, contextKey, optionsAsStrings);
    setConfigurationData(newData);
  };

  return (
    <AdvancedMultiSelect
      {...selectProps}
      options={options}
      onChange={onSelectChange}
      value={valueAsOptions}
    />
  );
};
