import Typography from "@mui/material/Typography";

import {
  StyledUnorderedList,
  TextContainer,
} from "../../../violationDrawerStyles";

export const getWhyDescription = () => (
  <TextContainer>
    Automatic certificate renewal is crucial for maintaining security,
    availability, and compliance by ensuring certificates are always valid, thus
    preventing potential vulnerabilities and service disruptions. It also
    enhances operational efficiency and user trust by eliminating the risk of
    expired certificates.
  </TextContainer>
);

export const howDescription = () => (
  <div>
    <Typography variant={"body2"}>
      If a certificate has passed its renewal date and was not renewed
      automatically, investigate the cause. Common reasons for renewal failures
      include:
    </Typography>
    <StyledUnorderedList $customPaddingLeft="24px">
      <li>
        <Typography variant={"body2"}>Misconfigured cert-manager</Typography>
      </li>
      <li>
        <Typography variant={"body2"}>Insufficient permissions</Typography>
      </li>
      <li>
        <Typography variant={"body2"}>
          Issues with the Certificate Issuer (e.g., ACME challenges not being
          completed)
        </Typography>
      </li>
    </StyledUnorderedList>
    <TextContainer>
      <TextContainer>
        Once you've identified the issue, you can{" "}
        <b>manually trigger a renewal</b> by deleting the CertificateRequest
        resource associated with the expired certificate. Cert-manager will
        automatically create a new CertificateRequest to renew the certificate.
      </TextContainer>
    </TextContainer>
  </div>
);
