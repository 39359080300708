import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { DisabledInput } from "@/components/Settings/email/DisabledInput";
import { useGetAllClusterNamesAsOptions } from "@/shared/hooks/useGetAllClusterNamesAsOptions";
import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";
import { CheckboxController } from "@/components/Settings/email/CheckboxController";
import { CheckboxGroupController } from "@/components/Settings/email/CheckboxGroupController";
import { SelectController } from "@/components/Settings/email/SelectController";
import { MultiSelectController } from "@/components/Settings/email/MultiSelectSelectController";
import { frequencySelectOptions } from "@/components/Settings/email/emailSettingsConstants";
import { useUserLoginInfo } from "@/shared/hooks/useUserMetadata/useUserLoginInfo";
import { useSetDataFromServer } from "@/components/Settings/email/emailSettingsHooks";

const Container = styled(Paper)`
  padding: 16px 24px;
`;

const LoaderContainer = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const ContentContainer = styled.div`
  min-width: 700px;
`;

export const EmailSettingsContent: React.FC = () => {
  const [showAlert, setShowAlert] = useState(true);
  const allClusterNamesAsOptions = useGetAllClusterNamesAsOptions();

  const { setConfigurationData, ...context } = useEmailSettingsContext();

  const onSubscribeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    setConfigurationData({ isSubscribed: value });
  };

  const userEmail = useUserLoginInfo()?.email ?? "";

  const subscribeValue = context.isSubscribed;

  const { isDataSet } = useSetDataFromServer();

  if (!isDataSet)
    return (
      <LoaderContainer>
        <LinesLoader />
      </LoaderContainer>
    );

  return (
    <Container>
      <ContentContainer>
        <TopSection>
          <Typography variant={"h4"}>Komodor Digest</Typography>
          <FlexContainer>
            <Typography variant={"body2"}>
              Subscribe to the Komodor digest
            </Typography>
            <Switch onChange={onSubscribeChange} checked={subscribeValue} />
          </FlexContainer>
        </TopSection>

        {showAlert && (
          <Alert
            variant={"standard"}
            severity="info"
            color="running"
            onClose={() => setShowAlert(false)}
          >
            NOTE: Email settings are personal! Any selections you make are for
            your user only.
          </Alert>
        )}

        <DisabledInput
          label={"Email address"}
          value={userEmail}
          helperText={"The digest will be sent to this email address"}
        />
        <SelectController
          options={frequencySelectOptions}
          contextKey={"frequency"}
          selectProps={{ label: "Send me the digest:" }}
        />
        <MultiSelectController
          options={allClusterNamesAsOptions}
          contextKey={"scope.clusters"}
          selectProps={{ placeholder: "select cluster", enableFreeSolo: true }}
        />

        <CheckboxController contentKey={"failedDeploys.isEnabled"} />
        <CheckboxController contentKey={"issues.isEnabled"} />
        <br />
        <br />
        <CheckboxGroupController
          groupKey={"reliability.isEnabled"}
          childrenContentKey={"reliability.violations.severities"}
        >
          <CheckboxController
            contentKey={"reliability.violations.severities.high"}
          />
          <CheckboxController
            contentKey={"reliability.violations.severities.medium"}
          />
          <CheckboxController
            contentKey={"reliability.violations.severities.low"}
          />
        </CheckboxGroupController>
      </ContentContainer>
    </Container>
  );
};
