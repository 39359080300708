import { useQuery } from "@tanstack/react-query";

import { useEmailDigestApiClient } from "@/shared/hooks/email-digest/apiClient";
import { useWorkspaceQueryKey } from "@/shared/hooks/workspaces-api/useWorkspaceQueryKey";
import {
  EmailDigestConfiguration,
  getEmailDigestConfigurationForUserUrl,
} from "@/generated/emailDigestService";

export const GET_EMAIL_DIGEST_CONFIGURATION_PATH = "/api/v1/user/configuration";

export const useEmailDigestConfiguration = () => {
  const apiClient = useEmailDigestApiClient();

  const url = getEmailDigestConfigurationForUserUrl(
    apiClient.defaults.baseURL ?? ""
  );
  const keys = useWorkspaceQueryKey([url]);

  return useQuery(
    keys,
    async () => await apiClient.get<EmailDigestConfiguration>(url)
  );
};
