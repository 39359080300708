import React from "react";

import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";

import { getWhyDescription, howDescription } from "./texts";
import { CertificatesTable } from "./CertificatesTable/CertificatesTable";

export const CertificateExpiration: React.FC = () => {
  const { data } = useViolation();

  const { supportingData, headerProps, summaryProps } = useGetCommonDrawerProps(
    data?.data.violation
  );

  const certificatesSupportingData = supportingData?.certificateExpiration;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <CertificatesTable supportingData={certificatesSupportingData} />
          }
          whyDescription={getWhyDescription()}
          howDescription={howDescription()}
        />
      }
    />
  );
};
