import React from "react";
import { get, set } from "lodash";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";
import { ContentKeyToLabel } from "@/components/Settings/email/emailSettingsConstants";

type CheckboxControllerProps = {
  contentKey: string;
};

export const CheckboxController: React.FC<CheckboxControllerProps> = ({
  contentKey,
}) => {
  const { setConfigurationData, content } = useEmailSettingsContext();
  const value = get(content, contentKey) ?? false;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    const newContent = set(content, contentKey, newValue);
    setConfigurationData({
      content: newContent,
    });
  };

  const label: string = get(ContentKeyToLabel, contentKey) ?? "";

  return (
    <FormControlLabel
      label={label}
      control={<Checkbox checked={value} onChange={onChange} />}
    />
  );
};
