import { DigestFrequency } from "@/generated/emailDigestService";

export const ContentKeyToLabel = {
  failedDeploys: { isEnabled: "Failed Deploys" },
  issues: { isEnabled: "Unhealthy services" },
  reliability: {
    isEnabled: "Reliability violations",
    violations: {
      severities: {
        high: "High severities",
        medium: "Medium severities",
        low: "Low severities",
      },
    },
  },
};

export const frequencySelectOptions = [
  { value: DigestFrequency.Daily, label: "Daily" },
  { value: DigestFrequency.Weekly, label: "Weekly" },
];
