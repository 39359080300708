/* tslint:disable */
/* eslint-disable */
/**
 * Reliability API
 * This API will allow clients to get info and also update resources, regarding risk analysis / cluster reliability
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface APIDeprecationsSupportingData
 */
export interface APIDeprecationsSupportingData {
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingData
     */
    'clusterVersion': string;
    /**
     * 
     * @type {ClusterProviderType}
     * @memberof APIDeprecationsSupportingData
     */
    'clusterProvider': ClusterProviderType;
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingData
     */
    'clusterProviderMaxKnownVersion': string;
    /**
     * 
     * @type {Array<APIDeprecationsSupportingDataDeprecatedAPIsInner>}
     * @memberof APIDeprecationsSupportingData
     */
    'deprecatedAPIs': Array<APIDeprecationsSupportingDataDeprecatedAPIsInner>;
}


        /**
 * 
 * @export
 * @interface APIDeprecationsSupportingDataDeprecatedAPIsInner
 */
export interface APIDeprecationsSupportingDataDeprecatedAPIsInner {
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'api'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'kind'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'deprecatedInVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'removedInVersion'?: string;
    /**
     * How many minor versions after current cluster version unti it is removed. Value of `1` means next version.
     * @type {number}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'removedAfterNMinors'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'replacementInVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'replacementApi'?: string;
    /**
     * Flag is true if replacement API exists in current cluster version
     * @type {boolean}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'replacementAlreadyPossible'?: boolean;
    /**
     * Flag is true if deprecation only happens in future version and does not affect current cluster version
     * @type {boolean}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'isFutureConcern'?: boolean;
    /**
     * How many minor versions after current cluster version unti it is deprecated. Value of `1` means next version.
     * @type {number}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'deprecatedAfterNMinors'?: number;
    /**
     * 
     * @type {Array<APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner>}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInner
     */
    'resources'?: Array<APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner>;
}
        /**
 * 
 * @export
 * @interface APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner
 */
export interface APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner {
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIDeprecationsSupportingDataDeprecatedAPIsInnerResourcesInner
     */
    'namespace'?: string;
}
        /**
 * 
 * @export
 * @interface ApiDeprecationSeverityConfiguration
 */
export interface ApiDeprecationSeverityConfiguration {
    /**
     * 
     * @type {ApiDeprecationState}
     * @memberof ApiDeprecationSeverityConfiguration
     */
    'state': ApiDeprecationState;
    /**
     * 
     * @type {number}
     * @memberof ApiDeprecationSeverityConfiguration
     */
    'versionsAhead': number;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ApiDeprecationState = {
    Removed: 'Removed',
    Deprecated: 'Deprecated'
} as const;

export type ApiDeprecationState = typeof ApiDeprecationState[keyof typeof ApiDeprecationState];


        /**
 * 
 * @export
 * @interface BasePolicy
 */
export interface BasePolicy {
    /**
     * 
     * @type {number}
     * @memberof BasePolicy
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof BasePolicy
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof BasePolicy
     */
    'createdAt': number;
    /**
     * 
     * @type {PolicyConfigurations}
     * @memberof BasePolicy
     */
    'configurations': PolicyConfigurations;
    /**
     * 
     * @type {string}
     * @memberof BasePolicy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BasePolicy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BasePolicy
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof BasePolicy
     */
    'updatedAt': number;
    /**
     * 
     * @type {PolicyScope}
     * @memberof BasePolicy
     */
    'scope': PolicyScope;
}
        /**
 * 
 * @export
 * @interface BasePolicyResponse
 */
export interface BasePolicyResponse {
    /**
     * 
     * @type {BasePolicy}
     * @memberof BasePolicyResponse
     */
    'policy': BasePolicy;
}
        /**
 * 
 * @export
 * @interface BaseViolationFilter
 */
export interface BaseViolationFilter {
    /**
     * 
     * @type {ViolationFilterOperator}
     * @memberof BaseViolationFilter
     */
    'operator': ViolationFilterOperator;
}


        /**
 * 
 * @export
 * @interface BasicCheck
 */
export interface BasicCheck {
    /**
     * 
     * @type {CheckType}
     * @memberof BasicCheck
     */
    'type': CheckType;
    /**
     * 
     * @type {string}
     * @memberof BasicCheck
     */
    'version'?: string;
}


        /**
 * 
 * @export
 * @interface BasicMessage
 */
export interface BasicMessage {
    /**
     * 
     * @type {MessageType}
     * @memberof BasicMessage
     */
    'type': MessageType;
}


        /**
 * 
 * @export
 * @interface BasicViolation
 */
export interface BasicViolation {
    /**
     * 
     * @type {string}
     * @memberof BasicViolation
     */
    'id': string;
    /**
     * 
     * @type {CheckType}
     * @memberof BasicViolation
     */
    'checkType': CheckType;
    /**
     * 
     * @type {string}
     * @memberof BasicViolation
     */
    'komodorUid': string;
    /**
     * 
     * @type {number}
     * @memberof BasicViolation
     */
    'createdAt': number;
    /**
     * 
     * @type {Severity}
     * @memberof BasicViolation
     */
    'severity': Severity;
    /**
     * 
     * @type {ViolationStatus}
     * @memberof BasicViolation
     */
    'status'?: ViolationStatus;
    /**
     * 
     * @type {ViolationSupportingData}
     * @memberof BasicViolation
     */
    'supportingData': ViolationSupportingData;
    /**
     * 
     * @type {number}
     * @memberof BasicViolation
     */
    'manuallyResolveUntil'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasicViolation
     */
    'hasDependentViolations': boolean;
}


        /**
 * 
 * @export
 * @interface CertificateExpirationConfigurationData
 */
export interface CertificateExpirationConfigurationData {
    /**
     * 
     * @type {boolean}
     * @memberof CertificateExpirationConfigurationData
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof CertificateExpirationConfigurationData
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof CertificateExpirationConfigurationData
     */
    'medium'?: number;
    /**
     * 
     * @type {number}
     * @memberof CertificateExpirationConfigurationData
     */
    'high'?: number;
}
        /**
 * 
 * @export
 * @interface CertificateExpirationData
 */
export interface CertificateExpirationData {
    /**
     * 
     * @type {string}
     * @memberof CertificateExpirationData
     */
    'certificateName': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateExpirationData
     */
    'certificateUid': string;
    /**
     * 
     * @type {number}
     * @memberof CertificateExpirationData
     */
    'daysToExpiration': number;
}
        /**
 * 
 * @export
 * @interface CertificateExpirationSupportingData
 */
export interface CertificateExpirationSupportingData {
    /**
     * 
     * @type {Array<CertificateExpirationData>}
     * @memberof CertificateExpirationSupportingData
     */
    'certificates': Array<CertificateExpirationData>;
}
        /**
 * 
 * @export
 * @interface Check
 */
export interface Check {
    /**
     * 
     * @type {ImpactGroupIdentifier}
     * @memberof Check
     */
    'impactGroupId': ImpactGroupIdentifier;
    /**
     * 
     * @type {CheckType}
     * @memberof Check
     */
    'type': CheckType;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    'version': string;
}


        /**
 * 
 * @export
 * @interface CheckConfigBase
 */
export interface CheckConfigBase {
    /**
     * 
     * @type {boolean}
     * @memberof CheckConfigBase
     */
    'enabled': boolean;
}
        /**
 * 
 * @export
 * @interface CheckResultMessage
 */
export interface CheckResultMessage {
    /**
     * 
     * @type {BasicCheck}
     * @memberof CheckResultMessage
     */
    'check': BasicCheck;
    /**
     * 
     * @type {ScopeMessage}
     * @memberof CheckResultMessage
     */
    'scope': ScopeMessage;
    /**
     * 
     * @type {Array<ResultMessage>}
     * @memberof CheckResultMessage
     */
    'results'?: Array<ResultMessage>;
    /**
     * 
     * @type {string}
     * @memberof CheckResultMessage
     */
    'resultsKey'?: string;
    /**
     * 
     * @type {CheckResultMessagePolicy}
     * @memberof CheckResultMessage
     */
    'policy': CheckResultMessagePolicy;
    /**
     * 
     * @type {MessageType}
     * @memberof CheckResultMessage
     */
    'type': MessageType;
}


        /**
 * Represents the partial policy used by the check-processor
 * @export
 * @interface CheckResultMessagePolicy
 */
export interface CheckResultMessagePolicy {
    /**
     * 
     * @type {string}
     * @memberof CheckResultMessagePolicy
     */
    'id': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const CheckType = {
    ThrottledCpu: 'throttledCPU',
    RequestsLimitsRatio: 'requestsLimitsRatio',
    DeprecatedApis: 'deprecatedApis',
    KubernetesEndOfLife: 'kubernetesEndOfLife',
    NoisyNeighbor: 'noisyNeighbor',
    UnschedulableTime: 'unschedulableTime',
    KubernetesVersionDeprecated: 'kubernetesVersionDeprecated',
    NodeTerminationAutoScaling: 'nodeTerminationAutoScaling',
    NodeTerminationSpotInstance: 'nodeTerminationSpotInstance',
    RestartingContainers: 'restartingContainers',
    HpaMax: 'HPAMax',
    UnderProvisionedWorkloads: 'underProvisionedWorkloads',
    SinglePointOfFailure: 'singlePointOfFailure',
    DeploymentMissingReplicas: 'deploymentMissingReplicas',
    MissingPdb: 'missingPDB',
    MissingTopologySpreadConstraint: 'missingTopologySpreadConstraint',
    HpaMinAvailability: 'HPAMinAvailability',
    MissingHpa: 'missingHPA',
    PriorityClassNotSet: 'priorityClassNotSet',
    CpuRequestsMissing: 'cpuRequestsMissing',
    CpuLimitsMissing: 'cpuLimitsMissing',
    MemoryRequestsMissing: 'memoryRequestsMissing',
    MemoryLimitsMissing: 'memoryLimitsMissing',
    LivenessProbeMissing: 'livenessProbeMissing',
    ReadinessProbeMissing: 'readinessProbeMissing',
    CertificateExpiration: 'certificateExpiration',
    SyntheticDegradedService: 'syntheticDegradedService',
    SyntheticNodePressure: 'syntheticNodePressure',
    SyntheticEolDeprecationOutdated: 'syntheticEOLDeprecationOutdated'
} as const;

export type CheckType = typeof CheckType[keyof typeof CheckType];


        /**
 * 
 * @export
 * @interface ClusterEOLConfigurationData
 */
export interface ClusterEOLConfigurationData {
    /**
     * 
     * @type {boolean}
     * @memberof ClusterEOLConfigurationData
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof ClusterEOLConfigurationData
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterEOLConfigurationData
     */
    'medium'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterEOLConfigurationData
     */
    'high'?: number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ClusterProviderType = {
    Aws: 'AWS',
    Gcp: 'GCP',
    Azure: 'AZURE',
    Kubernetes: 'KUBERNETES'
} as const;

export type ClusterProviderType = typeof ClusterProviderType[keyof typeof ClusterProviderType];


        /**
 * 
 * @export
 * @interface ContainerRestartsSupportingData
 */
export interface ContainerRestartsSupportingData {
    /**
     * 
     * @type {Array<ContainerRestartsSupportingDataContainersInner>}
     * @memberof ContainerRestartsSupportingData
     */
    'containers': Array<ContainerRestartsSupportingDataContainersInner>;
}
        /**
 * 
 * @export
 * @interface ContainerRestartsSupportingDataContainersInner
 */
export interface ContainerRestartsSupportingDataContainersInner {
    /**
     * 
     * @type {string}
     * @memberof ContainerRestartsSupportingDataContainersInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ContainerRestartsSupportingDataContainersInner
     */
    'restarts': number;
}
        /**
 * 
 * @export
 * @interface ContainerUsageRequestData
 */
export interface ContainerUsageRequestData {
    /**
     * 
     * @type {string}
     * @memberof ContainerUsageRequestData
     */
    'containerName': string;
    /**
     * 
     * @type {ResourceType}
     * @memberof ContainerUsageRequestData
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {number}
     * @memberof ContainerUsageRequestData
     */
    'ratio': number;
    /**
     * 
     * @type {number}
     * @memberof ContainerUsageRequestData
     */
    'diff': number;
}


        /**
 * 
 * @export
 * @interface CorrelatedIssueSupportingData
 */
export interface CorrelatedIssueSupportingData {
    /**
     * 
     * @type {string}
     * @memberof CorrelatedIssueSupportingData
     */
    'issueType': string;
    /**
     * 
     * @type {string}
     * @memberof CorrelatedIssueSupportingData
     */
    'id': string;
}
        /**
 * 
 * @export
 * @interface CreateOrUpdatePolicyErrorResponse
 */
export interface CreateOrUpdatePolicyErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdatePolicyErrorResponse
     */
    'message': string;
    /**
     * 
     * @type {Array<ErrorsMappingInner>}
     * @memberof CreateOrUpdatePolicyErrorResponse
     */
    'fieldErrors': Array<ErrorsMappingInner>;
}
        /**
 * 
 * @export
 * @interface CreateOrUpdatePolicyRequest
 */
export interface CreateOrUpdatePolicyRequest {
    /**
     * 
     * @type {BasePolicy}
     * @memberof CreateOrUpdatePolicyRequest
     */
    'policy': BasePolicy;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const DefaultPolicyID = {
    KomodorDefault: 'komodor-default'
} as const;

export type DefaultPolicyID = typeof DefaultPolicyID[keyof typeof DefaultPolicyID];


        /**
 * 
 * @export
 * @interface DeleteIgnoreRulesResponse
 */
export interface DeleteIgnoreRulesResponse {
    /**
     * 
     * @type {DeleteIgnoreRulesResponseIgnoreRule}
     * @memberof DeleteIgnoreRulesResponse
     */
    'ignoreRule': DeleteIgnoreRulesResponseIgnoreRule;
}
        /**
 * 
 * @export
 * @interface DeleteIgnoreRulesResponseIgnoreRule
 */
export interface DeleteIgnoreRulesResponseIgnoreRule {
    /**
     * 
     * @type {string}
     * @memberof DeleteIgnoreRulesResponseIgnoreRule
     */
    'id': string;
}
        /**
 * 
 * @export
 * @interface DeletePolicyResponse
 */
export interface DeletePolicyResponse {
    /**
     * 
     * @type {DeletePolicyResponsePolicy}
     * @memberof DeletePolicyResponse
     */
    'policy': DeletePolicyResponsePolicy;
}
        /**
 * 
 * @export
 * @interface DeletePolicyResponsePolicy
 */
export interface DeletePolicyResponsePolicy {
    /**
     * 
     * @type {string}
     * @memberof DeletePolicyResponsePolicy
     */
    'id': string;
}
        /**
 * 
 * @export
 * @interface DeprecatedApisConfigurationData
 */
export interface DeprecatedApisConfigurationData {
    /**
     * 
     * @type {ApiDeprecationSeverityConfiguration}
     * @memberof DeprecatedApisConfigurationData
     */
    'low'?: ApiDeprecationSeverityConfiguration;
    /**
     * 
     * @type {ApiDeprecationSeverityConfiguration}
     * @memberof DeprecatedApisConfigurationData
     */
    'medium'?: ApiDeprecationSeverityConfiguration;
    /**
     * 
     * @type {ApiDeprecationSeverityConfiguration}
     * @memberof DeprecatedApisConfigurationData
     */
    'high'?: ApiDeprecationSeverityConfiguration;
    /**
     * 
     * @type {boolean}
     * @memberof DeprecatedApisConfigurationData
     */
    'enabled': boolean;
}
        /**
 * 
 * @export
 * @interface ErrorsMappingInner
 */
export interface ErrorsMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ErrorsMappingInner
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorsMappingInner
     */
    'field': string;
}
        /**
 * 
 * @export
 * @interface ExtendedViolation
 */
export interface ExtendedViolation {
    /**
     * 
     * @type {number}
     * @memberof ExtendedViolation
     */
    'lastEvaluatedAt': number;
    /**
     * 
     * @type {string}
     * @memberof ExtendedViolation
     */
    'policyId': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedViolation
     */
    'policyName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedViolation
     */
    'dependsOnViolationsIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedViolation
     */
    'dependentByViolationsIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExtendedViolation
     */
    'id': string;
    /**
     * 
     * @type {CheckType}
     * @memberof ExtendedViolation
     */
    'checkType': CheckType;
    /**
     * 
     * @type {string}
     * @memberof ExtendedViolation
     */
    'komodorUid': string;
    /**
     * 
     * @type {number}
     * @memberof ExtendedViolation
     */
    'createdAt': number;
    /**
     * 
     * @type {Severity}
     * @memberof ExtendedViolation
     */
    'severity': Severity;
    /**
     * 
     * @type {ViolationStatus}
     * @memberof ExtendedViolation
     */
    'status'?: ViolationStatus;
    /**
     * 
     * @type {ViolationSupportingData}
     * @memberof ExtendedViolation
     */
    'supportingData': ViolationSupportingData;
    /**
     * 
     * @type {number}
     * @memberof ExtendedViolation
     */
    'manuallyResolveUntil'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedViolation
     */
    'hasDependentViolations': boolean;
}


        /**
 * 
 * @export
 * @interface GenericViolationFilterCategory
 */
export interface GenericViolationFilterCategory {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericViolationFilterCategory
     */
    'value': Array<string>;
    /**
     * 
     * @type {ViolationCountBy}
     * @memberof GenericViolationFilterCategory
     */
    'category': ViolationCountBy;
    /**
     * 
     * @type {ViolationFilterOperator}
     * @memberof GenericViolationFilterCategory
     */
    'operator': ViolationFilterOperator;
}


        /**
 * 
 * @export
 * @interface GetAllChecksResponse
 */
export interface GetAllChecksResponse {
    /**
     * 
     * @type {Array<Check>}
     * @memberof GetAllChecksResponse
     */
    'checks': Array<Check>;
}
        /**
 * 
 * @export
 * @interface GetAllIgnoreRulesPerChecksResponse
 */
export interface GetAllIgnoreRulesPerChecksResponse {
    /**
     * 
     * @type {Array<IgnoreRule>}
     * @memberof GetAllIgnoreRulesPerChecksResponse
     */
    'ignoreRules': Array<IgnoreRule>;
}
        /**
 * 
 * @export
 * @interface GetAllPoliciesResponse
 */
export interface GetAllPoliciesResponse {
    /**
     * 
     * @type {Array<PolicyListItem>}
     * @memberof GetAllPoliciesResponse
     */
    'policies': Array<PolicyListItem>;
}
        /**
 * 
 * @export
 * @interface GetPolicyResponse
 */
export interface GetPolicyResponse {
    /**
     * 
     * @type {BasePolicy}
     * @memberof GetPolicyResponse
     */
    'policy': BasePolicy;
}
        /**
 * 
 * @export
 * @interface GetUpdateViolationResponse
 */
export interface GetUpdateViolationResponse {
    /**
     * 
     * @type {BasicViolation}
     * @memberof GetUpdateViolationResponse
     */
    'violation': BasicViolation;
}
        /**
 * 
 * @export
 * @interface GetViolationHistoryResponse
 */
export interface GetViolationHistoryResponse {
    /**
     * 
     * @type {Array<ViolationHistoryItem>}
     * @memberof GetViolationHistoryResponse
     */
    'history': Array<ViolationHistoryItem>;
}
        /**
 * 
 * @export
 * @interface GetViolationResponse
 */
export interface GetViolationResponse {
    /**
     * 
     * @type {ExtendedViolation}
     * @memberof GetViolationResponse
     */
    'violation': ExtendedViolation;
}
        /**
 * @type GetViolationsCountResponse
 * @export
 */
export type GetViolationsCountResponse = ViolationsSeveritiesCountResponse;

        /**
 * 
 * @export
 * @interface GetViolationsResponse
 */
export interface GetViolationsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetViolationsResponse
     */
    'totalResults': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetViolationsResponse
     */
    'hasMoreResults': boolean;
    /**
     * 
     * @type {Array<BasicViolation>}
     * @memberof GetViolationsResponse
     */
    'violations': Array<BasicViolation>;
}
        /**
 * 
 * @export
 * @interface GetViolationsStatsResponse
 */
export interface GetViolationsStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetViolationsStatsResponse
     */
    'numOfTotalViolations': number;
    /**
     * 
     * @type {Array<ViolationsAggregationGroup>}
     * @memberof GetViolationsStatsResponse
     */
    'groups': Array<ViolationsAggregationGroup>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const GroupViolationsByOptions = {
    ImpactGroup: 'impact-group',
    Cluster: 'cluster',
    CheckType: 'checkType'
} as const;

export type GroupViolationsByOptions = typeof GroupViolationsByOptions[keyof typeof GroupViolationsByOptions];


        /**
 * 
 * @export
 * @interface HPAMaxConfigurationData
 */
export interface HPAMaxConfigurationData {
    /**
     * 
     * @type {boolean}
     * @memberof HPAMaxConfigurationData
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof HPAMaxConfigurationData
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof HPAMaxConfigurationData
     */
    'medium'?: number;
    /**
     * 
     * @type {number}
     * @memberof HPAMaxConfigurationData
     */
    'high'?: number;
}
        /**
 * 
 * @export
 * @interface HPAMaxSupportingData
 */
export interface HPAMaxSupportingData {
    /**
     * 
     * @type {string}
     * @memberof HPAMaxSupportingData
     */
    'hpaUid': string;
    /**
     * 
     * @type {number}
     * @memberof HPAMaxSupportingData
     */
    'fractionAtMax': number;
    /**
     * 
     * @type {number}
     * @memberof HPAMaxSupportingData
     */
    'secondsAtMax': number;
}
        /**
 * 
 * @export
 * @interface HPAMinAvailabilitySupportingData
 */
export interface HPAMinAvailabilitySupportingData {
    /**
     * 
     * @type {string}
     * @memberof HPAMinAvailabilitySupportingData
     */
    'hpaName': string;
}
        /**
 * 
 * @export
 * @interface HighRequestLimitsRatioContainerData
 */
export interface HighRequestLimitsRatioContainerData {
    /**
     * 
     * @type {string}
     * @memberof HighRequestLimitsRatioContainerData
     */
    'containerName': string;
    /**
     * 
     * @type {ResourceType}
     * @memberof HighRequestLimitsRatioContainerData
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {number}
     * @memberof HighRequestLimitsRatioContainerData
     */
    'ratio': number;
    /**
     * 
     * @type {number}
     * @memberof HighRequestLimitsRatioContainerData
     */
    'diff': number;
}


        /**
 * 
 * @export
 * @interface HighRequestsLimitsRatioSupportingData
 */
export interface HighRequestsLimitsRatioSupportingData {
    /**
     * 
     * @type {Array<HighRequestLimitsRatioContainerData>}
     * @memberof HighRequestsLimitsRatioSupportingData
     */
    'containersData'?: Array<HighRequestLimitsRatioContainerData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HighRequestsLimitsRatioSupportingData
     */
    'containers': Array<string>;
}
        /**
 * 
 * @export
 * @interface IgnoreRule
 */
export interface IgnoreRule {
    /**
     * 
     * @type {string}
     * @memberof IgnoreRule
     */
    'id': string;
    /**
     * 
     * @type {CheckType}
     * @memberof IgnoreRule
     */
    'checkType': CheckType;
    /**
     * 
     * @type {Scope}
     * @memberof IgnoreRule
     */
    'scope': Scope;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ImpactGroupCategory = {
    NoisyNeighbors: 'NoisyNeighbors',
    Victims: 'Victims',
    UnderProvisionedWorkloads: 'UnderProvisionedWorkloads',
    UnplannedResourceUsage: 'UnplannedResourceUsage',
    UnschedulableTime: 'UnschedulableTime',
    UnschedulablePods: 'UnschedulablePods',
    HpasReachedMax: 'HPAsReachedMax',
    DeprecatedApisInUse: 'DeprecatedAPIsInUse',
    DeprecatedApisInFuture: 'DeprecatedAPIsInFuture',
    RemovedApisInUse: 'RemovedAPIsInUse',
    DeprecatedClustersDetected: 'DeprecatedClustersDetected',
    ClustersWillDeprecateInFuture: 'ClustersWillDeprecateInFuture',
    NodeAutoscaling: 'NodeAutoscaling',
    SpotInterruptions: 'SpotInterruptions',
    RelatedAvailabilityIssues: 'RelatedAvailabilityIssues',
    ServicesThrottled: 'ServicesThrottled',
    ContainerRestarts: 'ContainerRestarts',
    SinglePointOfFailureServices: 'SinglePointOfFailureServices',
    BestPractices: 'BestPractices',
    ExpiredWorkloadCertificates: 'ExpiredWorkloadCertificates',
    AboutToExpireWorkloadCertificates: 'AboutToExpireWorkloadCertificates'
} as const;

export type ImpactGroupCategory = typeof ImpactGroupCategory[keyof typeof ImpactGroupCategory];


        /**
 * 
 * @export
 * @enum {string}
 */

export const ImpactGroupIdentifier = {
    DegradedService: 'DegradedService',
    EolDeprecationOutdated: 'EOLDeprecationOutdated',
    NodeTerminations: 'NodeTerminations',
    FlakyServices: 'FlakyServices',
    NodePressure: 'NodePressure',
    BestPractices: 'BestPractices',
    CertificateManagement: 'CertificateManagement'
} as const;

export type ImpactGroupIdentifier = typeof ImpactGroupIdentifier[keyof typeof ImpactGroupIdentifier];


        /**
 * 
 * @export
 * @enum {string}
 */

export const ImpactGroupName = {
    NodePressure: 'Node Pressure',
    DegradedService: 'Degraded Service',
    EolDeprecationOutDated: 'EOL / Deprecation / Out-dated',
    NodeTerminations: 'Node Terminations',
    FlakyServices: 'Flaky Services',
    BestPractices: 'Best Practices',
    CertificateManagement: 'Certificate Management'
} as const;

export type ImpactGroupName = typeof ImpactGroupName[keyof typeof ImpactGroupName];


        /**
 * 
 * @export
 * @enum {string}
 */

export const ImpactGroupType = {
    Static: 'static',
    Dynamic: 'dynamic'
} as const;

export type ImpactGroupType = typeof ImpactGroupType[keyof typeof ImpactGroupType];


        /**
 * 
 * @export
 * @interface InsertIgnoreRulesResponse
 */
export interface InsertIgnoreRulesResponse {
    /**
     * 
     * @type {IgnoreRule}
     * @memberof InsertIgnoreRulesResponse
     */
    'ignoreRule': IgnoreRule;
}
        /**
 * 
 * @export
 * @interface K8SEOLSupportingData
 */
export interface K8SEOLSupportingData {
    /**
     * 
     * @type {ClusterProviderType}
     * @memberof K8SEOLSupportingData
     */
    'clusterProvider': ClusterProviderType;
    /**
     * 
     * @type {string}
     * @memberof K8SEOLSupportingData
     */
    'clusterVersionShort'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8SEOLSupportingData
     */
    'clusterVersionGit': string;
    /**
     * 
     * @type {string}
     * @memberof K8SEOLSupportingData
     */
    'suggestedVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8SEOLSupportingData
     */
    'suggestedVersionEOL'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8SEOLSupportingData
     */
    'eolDate': string;
    /**
     * 
     * @type {number}
     * @memberof K8SEOLSupportingData
     */
    'daysToEol'?: number;
    /**
     * 
     * @type {number}
     * @memberof K8SEOLSupportingData
     */
    'monthsToEol'?: number;
    /**
     * 
     * @type {number}
     * @memberof K8SEOLSupportingData
     */
    'deprecatedApis'?: number;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const KillEventType = {
    OomKilled: 'OOMKilled',
    Eviction: 'Eviction'
} as const;

export type KillEventType = typeof KillEventType[keyof typeof KillEventType];


        /**
 * 
 * @export
 * @interface MemoryVictim
 */
export interface MemoryVictim {
    /**
     * 
     * @type {string}
     * @memberof MemoryVictim
     */
    'node': string;
    /**
     * 
     * @type {number}
     * @memberof MemoryVictim
     */
    'serviceUsageBytes': number;
    /**
     * 
     * @type {number}
     * @memberof MemoryVictim
     */
    'nodeCapacityBytes': number;
    /**
     * 
     * @type {number}
     * @memberof MemoryVictim
     */
    'totalVictims': number;
    /**
     * 
     * @type {Array<VictimInstances>}
     * @memberof MemoryVictim
     */
    'nodeVictims': Array<VictimInstances>;
}
        /**
 * 
 * @export
 * @interface MemoryVictimEvent
 */
export interface MemoryVictimEvent {
    /**
     * 
     * @type {string}
     * @memberof MemoryVictimEvent
     */
    'eventId': string;
    /**
     * 
     * @type {number}
     * @memberof MemoryVictimEvent
     */
    'eventTimestampSec': number;
    /**
     * 
     * @type {KillEventType}
     * @memberof MemoryVictimEvent
     */
    'eventType': KillEventType;
    /**
     * 
     * @type {string}
     * @memberof MemoryVictimEvent
     */
    'podName': string;
    /**
     * 
     * @type {string}
     * @memberof MemoryVictimEvent
     */
    'containerName'?: string;
}


        /**
 * Represents the different type of queue messages across the reliability system
 * @export
 * @enum {string}
 */

export const MessageType = {
    CheckRunResult: 'check_run_result',
    TriggerCheckRequest: 'trigger_check_request'
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];


        /**
 * 
 * @export
 * @interface MinimalPolicy
 */
export interface MinimalPolicy {
    /**
     * 
     * @type {string}
     * @memberof MinimalPolicy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MinimalPolicy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MinimalPolicy
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalPolicy
     */
    'updatedAt': number;
    /**
     * 
     * @type {PolicyScope}
     * @memberof MinimalPolicy
     */
    'scope': PolicyScope;
}
        /**
 * 
 * @export
 * @interface NodeTermVictimServicesInner
 */
export interface NodeTermVictimServicesInner {
    /**
     * 
     * @type {string}
     * @memberof NodeTermVictimServicesInner
     */
    'komodorUid': string;
    /**
     * 
     * @type {number}
     * @memberof NodeTermVictimServicesInner
     */
    'terminatedPods': number;
}
        /**
 * 
 * @export
 * @interface NodeTerminationSupportingData
 */
export interface NodeTerminationSupportingData {
    /**
     * 
     * @type {number}
     * @memberof NodeTerminationSupportingData
     */
    'numEvents': number;
    /**
     * 
     * @type {number}
     * @memberof NodeTerminationSupportingData
     */
    'numPodTerminations': number;
    /**
     * 
     * @type {number}
     * @memberof NodeTerminationSupportingData
     */
    'numAvailabilityIssues': number;
    /**
     * Duration in seconds
     * @type {number}
     * @memberof NodeTerminationSupportingData
     */
    'availabilityIssuesDuration': number;
    /**
     * 
     * @type {Array<NodeTermVictimServicesInner>}
     * @memberof NodeTerminationSupportingData
     */
    'affectedServices'?: Array<NodeTermVictimServicesInner>;
    /**
     * 
     * @type {Array<NodeTermVictimServicesInner>}
     * @memberof NodeTerminationSupportingData
     */
    'affectedJobs'?: Array<NodeTermVictimServicesInner>;
    /**
     * 
     * @type {Array<NodeTermVictimServicesInner>}
     * @memberof NodeTerminationSupportingData
     */
    'affectedOrphanPods'?: Array<NodeTermVictimServicesInner>;
    /**
     * 
     * @type {Array<NodeTermVictimServicesInner>}
     * @memberof NodeTerminationSupportingData
     */
    'affectedArgoWorkflows'?: Array<NodeTermVictimServicesInner>;
    /**
     * 
     * @type {Array<NodeTermVictimServicesInner>}
     * @memberof NodeTerminationSupportingData
     */
    'affectedDaemonSets'?: Array<NodeTermVictimServicesInner>;
}
        /**
 * 
 * @export
 * @interface NoisyNeighborConfigurationData
 */
export interface NoisyNeighborConfigurationData {
    /**
     * 
     * @type {PolicyThresholdBase}
     * @memberof NoisyNeighborConfigurationData
     */
    'victims': PolicyThresholdBase;
    /**
     * 
     * @type {boolean}
     * @memberof NoisyNeighborConfigurationData
     */
    'enabled': boolean;
}
        /**
 * 
 * @export
 * @interface NoisyNeighborSupportingData
 */
export interface NoisyNeighborSupportingData {
    /**
     * 
     * @type {number}
     * @memberof NoisyNeighborSupportingData
     */
    'totalVictims': number;
    /**
     * 
     * @type {number}
     * @memberof NoisyNeighborSupportingData
     */
    'totalAffectedNodes': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoisyNeighborSupportingData
     */
    'noisyContainers': Array<string>;
    /**
     * 
     * @type {Array<MemoryVictim>}
     * @memberof NoisyNeighborSupportingData
     */
    'memoryVictims': Array<MemoryVictim>;
}
        /**
 * 
 * @export
 * @interface PartialPolicy
 */
export interface PartialPolicy {
    /**
     * 
     * @type {number}
     * @memberof PartialPolicy
     */
    'priority'?: number;
    /**
     * 
     * @type {PolicyConfigurations}
     * @memberof PartialPolicy
     */
    'configurations'?: PolicyConfigurations;
    /**
     * 
     * @type {string}
     * @memberof PartialPolicy
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialPolicy
     */
    'description'?: string;
    /**
     * 
     * @type {PolicyScope}
     * @memberof PartialPolicy
     */
    'scope'?: PolicyScope;
}
        /**
 * 
 * @export
 * @interface PolicyConfigurations
 */
export interface PolicyConfigurations {
    /**
     * 
     * @type {ThrottledCPUConfigurationData}
     * @memberof PolicyConfigurations
     */
    'throttledCPU'?: ThrottledCPUConfigurationData;
    /**
     * 
     * @type {HPAMaxConfigurationData}
     * @memberof PolicyConfigurations
     */
    'HPAMax'?: HPAMaxConfigurationData;
    /**
     * 
     * @type {RestartingContainersConfigurationData}
     * @memberof PolicyConfigurations
     */
    'restartingContainers'?: RestartingContainersConfigurationData;
    /**
     * 
     * @type {UnderProvisionedWorkloadsConfigurationData}
     * @memberof PolicyConfigurations
     */
    'underProvisionedWorkloads'?: UnderProvisionedWorkloadsConfigurationData;
    /**
     * 
     * @type {NoisyNeighborConfigurationData}
     * @memberof PolicyConfigurations
     */
    'noisyNeighbor'?: NoisyNeighborConfigurationData;
    /**
     * 
     * @type {DeprecatedApisConfigurationData}
     * @memberof PolicyConfigurations
     */
    'deprecatedApis'?: DeprecatedApisConfigurationData;
    /**
     * 
     * @type {ClusterEOLConfigurationData}
     * @memberof PolicyConfigurations
     */
    'kubernetesEndOfLife'?: ClusterEOLConfigurationData;
    /**
     * 
     * @type {SinglePointOfFailureConfigurationData}
     * @memberof PolicyConfigurations
     */
    'singlePointOfFailure'?: SinglePointOfFailureConfigurationData;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'cpuLimitsMissing'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'cpuRequestsMissing'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'deploymentMissingReplicas'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'HPAMinAvailability'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'livenessProbeMissing'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'memoryLimitsMissing'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'memoryRequestsMissing'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'missingHPA'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'missingPDB'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'missingTopologySpreadConstraint'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'priorityClassNotSet'?: CheckConfigBase;
    /**
     * 
     * @type {CheckConfigBase}
     * @memberof PolicyConfigurations
     */
    'readinessProbeMissing'?: CheckConfigBase;
    /**
     * 
     * @type {CertificateExpirationConfigurationData}
     * @memberof PolicyConfigurations
     */
    'certificateExpiration'?: CertificateExpirationConfigurationData;
}
        /**
 * 
 * @export
 * @interface PolicyListItem
 */
export interface PolicyListItem {
    /**
     * 
     * @type {string}
     * @memberof PolicyListItem
     */
    'updatedBy': string;
    /**
     * 
     * @type {number}
     * @memberof PolicyListItem
     */
    'configurationsCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyListItem
     */
    'isDefaultPolicy': boolean;
    /**
     * 
     * @type {number}
     * @memberof PolicyListItem
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof PolicyListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PolicyListItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PolicyListItem
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PolicyListItem
     */
    'updatedAt': number;
    /**
     * 
     * @type {PolicyScope}
     * @memberof PolicyListItem
     */
    'scope': PolicyScope;
}
        /**
 * 
 * @export
 * @interface PolicyOrderItem
 */
export interface PolicyOrderItem {
    /**
     * 
     * @type {string}
     * @memberof PolicyOrderItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PolicyOrderItem
     */
    'priority': number;
}
        /**
 * 
 * @export
 * @interface PolicyScope
 */
export interface PolicyScope {
    /**
     * this field describes how a cluster should be matched with.
     * @type {Array<string>}
     * @memberof PolicyScope
     */
    'clusterRegexes'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface PolicyThresholdBase
 */
export interface PolicyThresholdBase {
    /**
     * 
     * @type {number}
     * @memberof PolicyThresholdBase
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyThresholdBase
     */
    'medium'?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyThresholdBase
     */
    'high'?: number;
}
        /**
 * 
 * @export
 * @interface PostViolationFeedbackResponse
 */
export interface PostViolationFeedbackResponse {
    /**
     * 
     * @type {ViolationFeedback}
     * @memberof PostViolationFeedbackResponse
     */
    'feedback': ViolationFeedback;
}
        /**
 * 
 * @export
 * @interface PostViolationsCountRequestBody
 */
export interface PostViolationsCountRequestBody {
    /**
     * 
     * @type {number}
     * @memberof PostViolationsCountRequestBody
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<GenericViolationFilterCategory>}
     * @memberof PostViolationsCountRequestBody
     */
    'filterBy': Array<GenericViolationFilterCategory>;
}
        /**
 * 
 * @export
 * @interface PostViolationsCountResponse
 */
export interface PostViolationsCountResponse {
    /**
     * 
     * @type {number}
     * @memberof PostViolationsCountResponse
     */
    'totalItems': number;
    /**
     * 
     * @type {Array<ViolationsFilterGroupValue>}
     * @memberof PostViolationsCountResponse
     */
    'items': Array<ViolationsFilterGroupValue>;
}
        /**
 * 
 * @export
 * @interface ReorderPoliciesPriorityRequest
 */
export interface ReorderPoliciesPriorityRequest {
    /**
     * the ordered policies
     * @type {Array<PolicyOrderItem>}
     * @memberof ReorderPoliciesPriorityRequest
     */
    'policies': Array<PolicyOrderItem>;
}
        /**
 * 
 * @export
 * @interface ReorderPoliciesPriorityResponse
 */
export interface ReorderPoliciesPriorityResponse {
    /**
     * the ordered policies
     * @type {Array<PolicyOrderItem>}
     * @memberof ReorderPoliciesPriorityResponse
     */
    'policies': Array<PolicyOrderItem>;
}
        /**
 * 
 * @export
 * @interface ResolvePolicyRequest
 */
export interface ResolvePolicyRequest {
    /**
     * 
     * @type {ResolvePolicyRequestScope}
     * @memberof ResolvePolicyRequest
     */
    'scope': ResolvePolicyRequestScope;
    /**
     * 
     * @type {CheckType}
     * @memberof ResolvePolicyRequest
     */
    'checkType': CheckType;
}


        /**
 * 
 * @export
 * @interface ResolvePolicyRequestScope
 */
export interface ResolvePolicyRequestScope {
    /**
     * 
     * @type {string}
     * @memberof ResolvePolicyRequestScope
     */
    'clusterName'?: string;
}
        /**
 * 
 * @export
 * @interface ResolvePolicyResponse
 */
export interface ResolvePolicyResponse {
    /**
     * 
     * @type {string}
     * @memberof ResolvePolicyResponse
     */
    'policyId': string;
    /**
     * 
     * @type {PolicyConfigurations}
     * @memberof ResolvePolicyResponse
     */
    'configuration': PolicyConfigurations;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ResourceType = {
    Cpu: 'CPU',
    Memory: 'Memory'
} as const;

export type ResourceType = typeof ResourceType[keyof typeof ResourceType];


        /**
 * 
 * @export
 * @interface RestartingContainersConfigurationData
 */
export interface RestartingContainersConfigurationData {
    /**
     * 
     * @type {boolean}
     * @memberof RestartingContainersConfigurationData
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof RestartingContainersConfigurationData
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof RestartingContainersConfigurationData
     */
    'medium'?: number;
    /**
     * 
     * @type {number}
     * @memberof RestartingContainersConfigurationData
     */
    'high'?: number;
}
        /**
 * 
 * @export
 * @interface ResultMessage
 */
export interface ResultMessage {
    /**
     * 
     * @type {string}
     * @memberof ResultMessage
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResultMessage
     */
    'timestamp': number;
    /**
     * 
     * @type {string}
     * @memberof ResultMessage
     */
    'failedResourcesKomodorUid': string;
    /**
     * 
     * @type {number}
     * @memberof ResultMessage
     */
    'score': number;
    /**
     * 
     * @type {Severity}
     * @memberof ResultMessage
     */
    'severity': Severity;
    /**
     * 
     * @type {ViolationSupportingData}
     * @memberof ResultMessage
     */
    'supportingData': ViolationSupportingData;
    /**
     * 
     * @type {Array<ResultMessageImpactDataInner>}
     * @memberof ResultMessage
     */
    'impactData': Array<ResultMessageImpactDataInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResultMessage
     */
    'dependsOnViolationIds'?: Array<string>;
}


        /**
 * 
 * @export
 * @interface ResultMessageImpactDataInner
 */
export interface ResultMessageImpactDataInner {
    /**
     * 
     * @type {ImpactGroupCategory}
     * @memberof ResultMessageImpactDataInner
     */
    'name': ImpactGroupCategory;
    /**
     * 
     * @type {number}
     * @memberof ResultMessageImpactDataInner
     */
    'value': number;
}


        /**
 * 
 * @export
 * @interface Scope
 */
export interface Scope {
    /**
     * An empty list means that ignore all clusters
     * @type {Array<string>}
     * @memberof Scope
     */
    'clusterNames': Array<string>;
    /**
     * An empty list means that ignore all namespaces
     * @type {Array<string>}
     * @memberof Scope
     */
    'namespaces': Array<string>;
    /**
     * An empty list means that ignore all service names
     * @type {Array<string>}
     * @memberof Scope
     */
    'shortResourceNames': Array<string>;
}
        /**
 * 
 * @export
 * @interface ScopeMessage
 */
export interface ScopeMessage {
    /**
     * 
     * @type {string}
     * @memberof ScopeMessage
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof ScopeMessage
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof ScopeMessage
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof ScopeMessage
     */
    'namespace'?: string;
    /**
     * 
     * @type {ScopeMessageTimeframe}
     * @memberof ScopeMessage
     */
    'timeframe': ScopeMessageTimeframe;
}
        /**
 * 
 * @export
 * @interface ScopeMessageTimeframe
 */
export interface ScopeMessageTimeframe {
    /**
     * 
     * @type {number}
     * @memberof ScopeMessageTimeframe
     */
    'startEpoch': number;
    /**
     * 
     * @type {number}
     * @memberof ScopeMessageTimeframe
     */
    'endEpoch': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const Severity = {
    High: 'high',
    Medium: 'medium',
    Low: 'low'
} as const;

export type Severity = typeof Severity[keyof typeof Severity];


        /**
 * 
 * @export
 * @interface SinglePointOfFailureConfigurationData
 */
export interface SinglePointOfFailureConfigurationData {
    /**
     * 
     * @type {boolean}
     * @memberof SinglePointOfFailureConfigurationData
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof SinglePointOfFailureConfigurationData
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof SinglePointOfFailureConfigurationData
     */
    'medium'?: number;
    /**
     * 
     * @type {number}
     * @memberof SinglePointOfFailureConfigurationData
     */
    'high'?: number;
}
        /**
 * 
 * @export
 * @interface SinglePointOfFailureSupportingData
 */
export interface SinglePointOfFailureSupportingData {
    /**
     * 
     * @type {number}
     * @memberof SinglePointOfFailureSupportingData
     */
    'unavailablePercentage': number;
    /**
     * 
     * @type {number}
     * @memberof SinglePointOfFailureSupportingData
     */
    'unavailableHours': number;
    /**
     * 
     * @type {number}
     * @memberof SinglePointOfFailureSupportingData
     */
    'unavailableMinutes': number;
    /**
     * 
     * @type {Array<CorrelatedIssueSupportingData>}
     * @memberof SinglePointOfFailureSupportingData
     */
    'correlatedIssues'?: Array<CorrelatedIssueSupportingData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SinglePointOfFailureSupportingData
     */
    'correlatedNodeEventIds': Array<string>;
    /**
     * 
     * @type {Array<SinglePointOfFailureSupportingDataCorrelatedViolationsInner>}
     * @memberof SinglePointOfFailureSupportingData
     */
    'correlatedViolations': Array<SinglePointOfFailureSupportingDataCorrelatedViolationsInner>;
}
        /**
 * 
 * @export
 * @interface SinglePointOfFailureSupportingDataCorrelatedViolationsInner
 */
export interface SinglePointOfFailureSupportingDataCorrelatedViolationsInner {
    /**
     * 
     * @type {string}
     * @memberof SinglePointOfFailureSupportingDataCorrelatedViolationsInner
     */
    'id': string;
    /**
     * 
     * @type {CheckType}
     * @memberof SinglePointOfFailureSupportingDataCorrelatedViolationsInner
     */
    'type': CheckType;
}


        /**
 * 
 * @export
 * @interface StaticCheckContainersSupportingData
 */
export interface StaticCheckContainersSupportingData {
    /**
     * 
     * @type {Array<string>}
     * @memberof StaticCheckContainersSupportingData
     */
    'containerNames': Array<string>;
}
        /**
 * 
 * @export
 * @interface SyntheticSupportingData
 */
export interface SyntheticSupportingData {
    /**
     * 
     * @type {string}
     * @memberof SyntheticSupportingData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SyntheticSupportingData
     */
    'drawerHTML': string;
}
        /**
 * 
 * @export
 * @interface ThrottledCPUConfigurationData
 */
export interface ThrottledCPUConfigurationData {
    /**
     * 
     * @type {boolean}
     * @memberof ThrottledCPUConfigurationData
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUConfigurationData
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUConfigurationData
     */
    'medium'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUConfigurationData
     */
    'high'?: number;
}
        /**
 * 
 * @export
 * @interface ThrottledCPUSupportingData
 */
export interface ThrottledCPUSupportingData {
    /**
     * 
     * @type {string}
     * @memberof ThrottledCPUSupportingData
     */
    'komodor_service_kind'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrottledCPUSupportingData
     */
    'komodor_service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrottledCPUSupportingData
     */
    'namespace'?: string;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUSupportingData
     */
    'total_throttled_hours': number;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUSupportingData
     */
    'total_throttled_pods': number;
    /**
     * 
     * @type {Array<ThrottledCPUSupportingDataContainersInner>}
     * @memberof ThrottledCPUSupportingData
     */
    'containers': Array<ThrottledCPUSupportingDataContainersInner>;
}
        /**
 * 
 * @export
 * @interface ThrottledCPUSupportingDataContainersInner
 */
export interface ThrottledCPUSupportingDataContainersInner {
    /**
     * 
     * @type {string}
     * @memberof ThrottledCPUSupportingDataContainersInner
     */
    'container': string;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUSupportingDataContainersInner
     */
    'measured_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUSupportingDataContainersInner
     */
    'throttled_fraction': number;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUSupportingDataContainersInner
     */
    'throttled_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThrottledCPUSupportingDataContainersInner
     */
    'throttled_pods'?: number;
}
        /**
 * 
 * @export
 * @interface TriggerCheckRequest
 */
export interface TriggerCheckRequest {
    /**
     * 
     * @type {TriggerCheckRequestPayload}
     * @memberof TriggerCheckRequest
     */
    'request': TriggerCheckRequestPayload;
}
        /**
 * This kind of message is sent via SQS to checks engine to trigger the prioritized check run
 * @export
 * @interface TriggerCheckRequestMessage
 */
export interface TriggerCheckRequestMessage {
    /**
     * 
     * @type {string}
     * @memberof TriggerCheckRequestMessage
     */
    'source'?: string;
    /**
     * 
     * @type {BasicCheck}
     * @memberof TriggerCheckRequestMessage
     */
    'check': BasicCheck;
    /**
     * 
     * @type {ScopeMessage}
     * @memberof TriggerCheckRequestMessage
     */
    'scope': ScopeMessage;
    /**
     * 
     * @type {MessageType}
     * @memberof TriggerCheckRequestMessage
     */
    'type': MessageType;
}


        /**
 * 
 * @export
 * @interface TriggerCheckRequestPayload
 */
export interface TriggerCheckRequestPayload {
    /**
     * 
     * @type {CheckType}
     * @memberof TriggerCheckRequestPayload
     */
    'checkType': CheckType;
    /**
     * 
     * @type {TriggerCheckRequestPayloadScope}
     * @memberof TriggerCheckRequestPayload
     */
    'scope': TriggerCheckRequestPayloadScope;
}


        /**
 * 
 * @export
 * @interface TriggerCheckRequestPayloadScope
 */
export interface TriggerCheckRequestPayloadScope {
    /**
     * 
     * @type {string}
     * @memberof TriggerCheckRequestPayloadScope
     */
    'clusterName': string;
    /**
     * 
     * @type {number}
     * @memberof TriggerCheckRequestPayloadScope
     */
    'startEpoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof TriggerCheckRequestPayloadScope
     */
    'endEpoch'?: number;
}
        /**
 * 
 * @export
 * @interface UnderProvisionedWorkloadsConfigurationData
 */
export interface UnderProvisionedWorkloadsConfigurationData {
    /**
     * 
     * @type {PolicyThresholdBase}
     * @memberof UnderProvisionedWorkloadsConfigurationData
     */
    'cpu': PolicyThresholdBase;
    /**
     * 
     * @type {PolicyThresholdBase}
     * @memberof UnderProvisionedWorkloadsConfigurationData
     */
    'memory': PolicyThresholdBase;
    /**
     * 
     * @type {boolean}
     * @memberof UnderProvisionedWorkloadsConfigurationData
     */
    'enabled': boolean;
}
        /**
 * 
 * @export
 * @interface UnderProvisionedWorkloadsSupportingData
 */
export interface UnderProvisionedWorkloadsSupportingData {
    /**
     * 
     * @type {Array<ContainerUsageRequestData>}
     * @memberof UnderProvisionedWorkloadsSupportingData
     */
    'containersData': Array<ContainerUsageRequestData>;
}
        /**
 * 
 * @export
 * @interface UpdateIgnoreRulesResponse
 */
export interface UpdateIgnoreRulesResponse {
    /**
     * 
     * @type {IgnoreRule}
     * @memberof UpdateIgnoreRulesResponse
     */
    'ignoreRule': IgnoreRule;
}
        /**
 * 
 * @export
 * @interface UpdatePolicyFieldsRequest
 */
export interface UpdatePolicyFieldsRequest {
    /**
     * 
     * @type {PartialPolicy}
     * @memberof UpdatePolicyFieldsRequest
     */
    'policy': PartialPolicy;
}
        /**
 * 
 * @export
 * @interface VictimInstances
 */
export interface VictimInstances {
    /**
     * 
     * @type {string}
     * @memberof VictimInstances
     */
    'komodorUid': string;
    /**
     * 
     * @type {string}
     * @memberof VictimInstances
     */
    'kind': string;
    /**
     * 
     * @type {number}
     * @memberof VictimInstances
     */
    'totalVictims': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof VictimInstances
     */
    'victimTimestampsSec': Array<number>;
    /**
     * 
     * @type {Array<MemoryVictimEvent>}
     * @memberof VictimInstances
     */
    'victimsEvents'?: Array<MemoryVictimEvent>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ViolationCountBy = {
    ClusterName: 'clusterName',
    Namespace: 'namespace',
    ResourceType: 'resourceType',
    ShortResourceName: 'shortResourceName',
    CheckType: 'checkType',
    Status: 'status',
    ImpactGroupId: 'impactGroupId',
    CreatedAt: 'createdAt',
    Severity: 'severity',
    KomodorUid: 'komodorUid',
    ImpactGroupType: 'impactGroupType',
    HasDependentViolations: 'hasDependentViolations'
} as const;

export type ViolationCountBy = typeof ViolationCountBy[keyof typeof ViolationCountBy];


        /**
 * 
 * @export
 * @interface ViolationFeedback
 */
export interface ViolationFeedback {
    /**
     * 
     * @type {string}
     * @memberof ViolationFeedback
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationFeedback
     */
    'violationUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ViolationFeedback
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationFeedback
     */
    'isPositive': boolean;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ViolationFilterOperator = {
    Include: 'include',
    Exclude: 'exclude',
    GreaterThan: '>',
    LessThan: '<',
    Like: 'like'
} as const;

export type ViolationFilterOperator = typeof ViolationFilterOperator[keyof typeof ViolationFilterOperator];


        /**
 * 
 * @export
 * @interface ViolationHistoryItem
 */
export interface ViolationHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryItem
     */
    'id': string;
    /**
     * 
     * @type {ViolationStatus}
     * @memberof ViolationHistoryItem
     */
    'status': ViolationStatus;
    /**
     * 
     * @type {Severity}
     * @memberof ViolationHistoryItem
     */
    'severity': Severity;
    /**
     * 
     * @type {number}
     * @memberof ViolationHistoryItem
     */
    'modifiedAt': number;
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryItem
     */
    'userEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryItem
     */
    'policyId': string;
}


        /**
 * 
 * @export
 * @interface ViolationImpactDataAggregation
 */
export interface ViolationImpactDataAggregation {
    /**
     * 
     * @type {ImpactGroupCategory}
     * @memberof ViolationImpactDataAggregation
     */
    'category': ImpactGroupCategory;
    /**
     * 
     * @type {ViolationStatsUnit}
     * @memberof ViolationImpactDataAggregation
     */
    'unit'?: ViolationStatsUnit;
    /**
     * 
     * @type {number}
     * @memberof ViolationImpactDataAggregation
     */
    'value': number;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const ViolationStatsUnit = {
    Time: 'time',
    Number: 'number'
} as const;

export type ViolationStatsUnit = typeof ViolationStatsUnit[keyof typeof ViolationStatsUnit];


        /**
 * 
 * @export
 * @enum {string}
 */

export const ViolationStatus = {
    Open: 'open',
    Confirmed: 'confirmed',
    Resolved: 'resolved',
    Dismissed: 'dismissed',
    Ignored: 'ignored',
    ManuallyResolved: 'manually_resolved'
} as const;

export type ViolationStatus = typeof ViolationStatus[keyof typeof ViolationStatus];


        /**
 * 
 * @export
 * @interface ViolationSupportingData
 */
export interface ViolationSupportingData {
    /**
     * 
     * @type {HighRequestsLimitsRatioSupportingData}
     * @memberof ViolationSupportingData
     */
    'requestsLimitsRatio'?: HighRequestsLimitsRatioSupportingData;
    /**
     * 
     * @type {NoisyNeighborSupportingData}
     * @memberof ViolationSupportingData
     */
    'noisyNeighbor'?: NoisyNeighborSupportingData;
    /**
     * 
     * @type {ThrottledCPUSupportingData}
     * @memberof ViolationSupportingData
     */
    'throttledCPU'?: ThrottledCPUSupportingData;
    /**
     * 
     * @type {K8SEOLSupportingData}
     * @memberof ViolationSupportingData
     */
    'kubernetesEndOfLife'?: K8SEOLSupportingData;
    /**
     * 
     * @type {APIDeprecationsSupportingData}
     * @memberof ViolationSupportingData
     */
    'deprecatedApis'?: APIDeprecationsSupportingData;
    /**
     * 
     * @type {HPAMaxSupportingData}
     * @memberof ViolationSupportingData
     */
    'HPAMax'?: HPAMaxSupportingData;
    /**
     * 
     * @type {ContainerRestartsSupportingData}
     * @memberof ViolationSupportingData
     */
    'restartingContainers'?: ContainerRestartsSupportingData;
    /**
     * 
     * @type {NodeTerminationSupportingData}
     * @memberof ViolationSupportingData
     */
    'nodeTerminationAutoScaling'?: NodeTerminationSupportingData;
    /**
     * 
     * @type {NodeTerminationSupportingData}
     * @memberof ViolationSupportingData
     */
    'nodeTerminationSpotInstance'?: NodeTerminationSupportingData;
    /**
     * 
     * @type {UnderProvisionedWorkloadsSupportingData}
     * @memberof ViolationSupportingData
     */
    'underProvisionedWorkloads'?: UnderProvisionedWorkloadsSupportingData;
    /**
     * 
     * @type {SinglePointOfFailureSupportingData}
     * @memberof ViolationSupportingData
     */
    'singlePointOfFailure'?: SinglePointOfFailureSupportingData;
    /**
     * 
     * @type {HPAMinAvailabilitySupportingData}
     * @memberof ViolationSupportingData
     */
    'HPAMinAvailability'?: HPAMinAvailabilitySupportingData;
    /**
     * 
     * @type {StaticCheckContainersSupportingData}
     * @memberof ViolationSupportingData
     */
    'cpuRequestsMissing'?: StaticCheckContainersSupportingData;
    /**
     * 
     * @type {StaticCheckContainersSupportingData}
     * @memberof ViolationSupportingData
     */
    'cpuLimitsMissing'?: StaticCheckContainersSupportingData;
    /**
     * 
     * @type {StaticCheckContainersSupportingData}
     * @memberof ViolationSupportingData
     */
    'memoryRequestsMissing'?: StaticCheckContainersSupportingData;
    /**
     * 
     * @type {StaticCheckContainersSupportingData}
     * @memberof ViolationSupportingData
     */
    'memoryLimitsMissing'?: StaticCheckContainersSupportingData;
    /**
     * 
     * @type {StaticCheckContainersSupportingData}
     * @memberof ViolationSupportingData
     */
    'livenessProbeMissing'?: StaticCheckContainersSupportingData;
    /**
     * 
     * @type {StaticCheckContainersSupportingData}
     * @memberof ViolationSupportingData
     */
    'readinessProbeMissing'?: StaticCheckContainersSupportingData;
    /**
     * 
     * @type {CertificateExpirationSupportingData}
     * @memberof ViolationSupportingData
     */
    'certificateExpiration'?: CertificateExpirationSupportingData;
    /**
     * 
     * @type {SyntheticSupportingData}
     * @memberof ViolationSupportingData
     */
    'syntheticData'?: SyntheticSupportingData;
}
        /**
 * 
 * @export
 * @interface ViolationsAggregationGroup
 */
export interface ViolationsAggregationGroup {
    /**
     * 
     * @type {string}
     * @memberof ViolationsAggregationGroup
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ViolationsAggregationGroup
     */
    'numOfTotalViolations'?: number;
    /**
     * 
     * @type {Array<ViolationImpactDataAggregation>}
     * @memberof ViolationsAggregationGroup
     */
    'impactDataAggregations'?: Array<ViolationImpactDataAggregation>;
    /**
     * Keys are expected to be values from the ViolationCountBy enum.
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ViolationsAggregationGroup
     */
    'breakdowns'?: { [key: string]: { [key: string]: number; }; };
}
        /**
 * 
 * @export
 * @interface ViolationsFilterGroupByGeneric
 */
export interface ViolationsFilterGroupByGeneric {
    /**
     * 
     * @type {number}
     * @memberof ViolationsFilterGroupByGeneric
     */
    'totalItems': number;
    /**
     * 
     * @type {Array<ViolationsFilterGroupValue>}
     * @memberof ViolationsFilterGroupByGeneric
     */
    'items': Array<ViolationsFilterGroupValue>;
}
        /**
 * 
 * @export
 * @interface ViolationsFilterGroupValue
 */
export interface ViolationsFilterGroupValue {
    /**
     * 
     * @type {string}
     * @memberof ViolationsFilterGroupValue
     */
    'key': string;
    /**
     * 
     * @type {number}
     * @memberof ViolationsFilterGroupValue
     */
    'value': number;
}
        /**
 * 
 * @export
 * @interface ViolationsSeveritiesCountResponse
 */
export interface ViolationsSeveritiesCountResponse {
    /**
     * 
     * @type {string}
     * @memberof ViolationsSeveritiesCountResponse
     */
    'countBy': ViolationsSeveritiesCountResponseCountByEnum;
    /**
     * 
     * @type {ViolationsSeveritiesMap}
     * @memberof ViolationsSeveritiesCountResponse
     */
    'severities': ViolationsSeveritiesMap;
}

export const ViolationsSeveritiesCountResponseCountByEnum = {
    Severity: 'severity'
} as const;

export type ViolationsSeveritiesCountResponseCountByEnum = typeof ViolationsSeveritiesCountResponseCountByEnum[keyof typeof ViolationsSeveritiesCountResponseCountByEnum];

        /**
 * 
 * @export
 * @interface ViolationsSeveritiesMap
 */
export interface ViolationsSeveritiesMap {
    /**
     * 
     * @type {number}
     * @memberof ViolationsSeveritiesMap
     */
    'high': number;
    /**
     * 
     * @type {number}
     * @memberof ViolationsSeveritiesMap
     */
    'medium': number;
    /**
     * 
     * @type {number}
     * @memberof ViolationsSeveritiesMap
     */
    'low': number;
}
    
        /**
* Get all the available checks
* @summary Get all the available checks
* @param baseUrl string
* @throws {RequiredError}
*/
export const getAllChecksUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/checks`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Trigger a check request to the reliability system
* @summary Trigger a check request to the reliability system
* @param params ChecksApiTriggerCheckRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const triggerCheckUrl = (params: ChecksApiTriggerCheckRequest, baseUrl: string): string => {
    // verify required parameter 'triggerCheckRequest' is not null or undefined
    assertParamExists('triggerCheck', 'triggerCheckRequest', params.triggerCheckRequest)
    const localVarPath = `/api/v1/checks/trigger`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for triggerCheck operation in ChecksApi.
* @export
* @interface ChecksApiTriggerCheckRequest
*/
export interface ChecksApiTriggerCheckRequest {
    /**
    * 
    * @type {TriggerCheckRequest}
    * @memberof ChecksApiTriggerCheck
    */
    readonly triggerCheckRequest: TriggerCheckRequest
}


    
        /**
* Delete an ignore rule.
* @summary Delete an ignore rule.
* @param params IgnoreRulesApiDeleteIgnoreRuleRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const deleteIgnoreRuleUrl = (params: IgnoreRulesApiDeleteIgnoreRuleRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('deleteIgnoreRule', 'id', params.id)
    const localVarPath = `/api/v1/checks/ignore-rules/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get ignore rules per list of check types.
* @summary Get ignore rules per list of check types.
* @param params IgnoreRulesApiGetIgnoreRulesPerCheckTypesRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getIgnoreRulesPerCheckTypesUrl = (params: IgnoreRulesApiGetIgnoreRulesPerCheckTypesRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/checks/ignore-rules`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.checkType) {
        localVarQueryParameter['checkType'] = params.checkType;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Insert an ignore rule.
* @summary Insert an ignore rule.
* @param params IgnoreRulesApiInsertIgnoreRuleRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const insertIgnoreRuleUrl = (params: IgnoreRulesApiInsertIgnoreRuleRequest, baseUrl: string): string => {
    // verify required parameter 'body' is not null or undefined
    assertParamExists('insertIgnoreRule', 'body', params.body)
    const localVarPath = `/api/v1/checks/ignore-rules`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update an ignore rule.
* @summary Update an ignore rule.
* @param params IgnoreRulesApiUpdateIgnoreRuleRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const updateIgnoreRuleUrl = (params: IgnoreRulesApiUpdateIgnoreRuleRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('updateIgnoreRule', 'id', params.id)
    // verify required parameter 'body' is not null or undefined
    assertParamExists('updateIgnoreRule', 'body', params.body)
    const localVarPath = `/api/v1/checks/ignore-rules/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for deleteIgnoreRule operation in IgnoreRulesApi.
* @export
* @interface IgnoreRulesApiDeleteIgnoreRuleRequest
*/
export interface IgnoreRulesApiDeleteIgnoreRuleRequest {
    /**
    * 
    * @type {string}
    * @memberof IgnoreRulesApiDeleteIgnoreRule
    */
    readonly id: string
}

/**
* Request parameters for getIgnoreRulesPerCheckTypes operation in IgnoreRulesApi.
* @export
* @interface IgnoreRulesApiGetIgnoreRulesPerCheckTypesRequest
*/
export interface IgnoreRulesApiGetIgnoreRulesPerCheckTypesRequest {
    /**
    * 
    * @type {Array<CheckType>}
    * @memberof IgnoreRulesApiGetIgnoreRulesPerCheckTypes
    */
    readonly checkType?: Array<CheckType>
}

/**
* Request parameters for insertIgnoreRule operation in IgnoreRulesApi.
* @export
* @interface IgnoreRulesApiInsertIgnoreRuleRequest
*/
export interface IgnoreRulesApiInsertIgnoreRuleRequest {
    /**
    * 
    * @type {IgnoreRule}
    * @memberof IgnoreRulesApiInsertIgnoreRule
    */
    readonly body: IgnoreRule
}

/**
* Request parameters for updateIgnoreRule operation in IgnoreRulesApi.
* @export
* @interface IgnoreRulesApiUpdateIgnoreRuleRequest
*/
export interface IgnoreRulesApiUpdateIgnoreRuleRequest {
    /**
    * 
    * @type {string}
    * @memberof IgnoreRulesApiUpdateIgnoreRule
    */
    readonly id: string

    /**
    * 
    * @type {IgnoreRule}
    * @memberof IgnoreRulesApiUpdateIgnoreRule
    */
    readonly body: IgnoreRule
}


    
        /**
* Get all the impact groups from the open violations.
* @summary Get all the impact groups from the open violations.
* @param params ImpactGroupsApiGetImpactGroupsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getImpactGroupsUrl = (params: ImpactGroupsApiGetImpactGroupsRequest, baseUrl: string): string => {
    // verify required parameter 'groupViolationsBy' is not null or undefined
    assertParamExists('getImpactGroups', 'groupViolationsBy', params.groupViolationsBy)
    // verify required parameter 'impactGroupType' is not null or undefined
    assertParamExists('getImpactGroups', 'impactGroupType', params.impactGroupType)
    const localVarPath = `/api/v1/violations/stats`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.clusterName) {
        localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace) {
        localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.groupViolationsBy !== undefined) {
            localVarQueryParameter['groupViolationsBy'] = params.groupViolationsBy;
    }
    if (params.breakdownFactors) {
        localVarQueryParameter['breakdownFactors'] = params.breakdownFactors;
    }
    if (params.resourceType) {
        localVarQueryParameter['resourceType'] = params.resourceType;
    }
    if (params.shortResourceName) {
        localVarQueryParameter['shortResourceName'] = params.shortResourceName;
    }
    if (params.createdFromEpoch !== undefined) {
            localVarQueryParameter['createdFromEpoch'] = params.createdFromEpoch;
    }
    if (params.createdToEpoch !== undefined) {
            localVarQueryParameter['createdToEpoch'] = params.createdToEpoch;
    }
    if (params.status) {
        localVarQueryParameter['status'] = params.status;
    }
    if (params.impactGroupType) {
        localVarQueryParameter['impactGroupType'] = params.impactGroupType;
    }
    if (params.severity) {
        localVarQueryParameter['severity'] = params.severity;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for getImpactGroups operation in ImpactGroupsApi.
* @export
* @interface ImpactGroupsApiGetImpactGroupsRequest
*/
export interface ImpactGroupsApiGetImpactGroupsRequest {
    /**
    * 
    * @type {GroupViolationsByOptions}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly groupViolationsBy: GroupViolationsByOptions

    /**
    * 
    * @type {Array<ImpactGroupType>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly impactGroupType: Array<ImpactGroupType>

    /**
    * used internally in order to impersonate an account
    * @type {string}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly accountId?: string

    /**
    * used internally in order to impersonate a user
    * @type {string}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly userId?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly clusterName?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly namespace?: Array<string>

    /**
    * 
    * @type {Array<ViolationCountBy>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly breakdownFactors?: Array<ViolationCountBy>

    /**
    * 
    * @type {Array<string>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly resourceType?: Array<string>

    /**
    * Use in order to search a service names
    * @type {Array<string>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly shortResourceName?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly createdFromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly createdToEpoch?: string

    /**
    * 
    * @type {Array<ViolationStatus>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly status?: Array<ViolationStatus>

    /**
    * 
    * @type {Array<Severity>}
    * @memberof ImpactGroupsApiGetImpactGroups
    */
    readonly severity?: Array<Severity>
}


    
        /**
* Create a policy.
* @summary Insert a policy.
* @param params PoliciesApiCreatePolicyRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const createPolicyUrl = (params: PoliciesApiCreatePolicyRequest, baseUrl: string): string => {
    // verify required parameter 'createOrUpdatePolicyRequest' is not null or undefined
    assertParamExists('createPolicy', 'createOrUpdatePolicyRequest', params.createOrUpdatePolicyRequest)
    const localVarPath = `/api/v1/policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Delete a policy.
* @summary Delete a policy.
* @param params PoliciesApiDeletePolicyRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const deletePolicyUrl = (params: PoliciesApiDeletePolicyRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('deletePolicy', 'id', params.id)
    const localVarPath = `/api/v1/policies/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get all the available policies.
* @summary Get all the available policies.
* @param baseUrl string
* @throws {RequiredError}
*/
export const getAllPoliciesUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get Policy data.
* @summary Get Policy data.
* @param params PoliciesApiGetPolicyRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getPolicyUrl = (params: PoliciesApiGetPolicyRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('getPolicy', 'id', params.id)
    const localVarPath = `/api/v1/policies/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Reorder the policies in order to set their priority to be resolved.
* @summary Reorder the policies.
* @param params PoliciesApiReorderPoliciesPriorityRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const reorderPoliciesPriorityUrl = (params: PoliciesApiReorderPoliciesPriorityRequest, baseUrl: string): string => {
    // verify required parameter 'reorderPoliciesPriorityRequest' is not null or undefined
    assertParamExists('reorderPoliciesPriority', 'reorderPoliciesPriorityRequest', params.reorderPoliciesPriorityRequest)
    const localVarPath = `/api/v1/policies/reorder`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get check configuration data, for the selected policy.
* @summary Get check configuration data, for the selected policy.
* @param params PoliciesApiResolvePolicyRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const resolvePolicyUrl = (params: PoliciesApiResolvePolicyRequest, baseUrl: string): string => {
    // verify required parameter 'resolvePolicyRequest' is not null or undefined
    assertParamExists('resolvePolicy', 'resolvePolicyRequest', params.resolvePolicyRequest)
    const localVarPath = `/api/v1/policies/resolve`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Update a policy.
* @summary Update a policy.
* @param params PoliciesApiUpdatePolicyRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const updatePolicyUrl = (params: PoliciesApiUpdatePolicyRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('updatePolicy', 'id', params.id)
    // verify required parameter 'createOrUpdatePolicyRequest' is not null or undefined
    assertParamExists('updatePolicy', 'createOrUpdatePolicyRequest', params.createOrUpdatePolicyRequest)
    const localVarPath = `/api/v1/policies/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update some policy fields.
* @summary Update some policy fields.
* @param params PoliciesApiUpdatePolicyFieldsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const updatePolicyFieldsUrl = (params: PoliciesApiUpdatePolicyFieldsRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('updatePolicyFields', 'id', params.id)
    // verify required parameter 'updatePolicyFieldsRequest' is not null or undefined
    assertParamExists('updatePolicyFields', 'updatePolicyFieldsRequest', params.updatePolicyFieldsRequest)
    const localVarPath = `/api/v1/policies/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for createPolicy operation in PoliciesApi.
* @export
* @interface PoliciesApiCreatePolicyRequest
*/
export interface PoliciesApiCreatePolicyRequest {
    /**
    * 
    * @type {CreateOrUpdatePolicyRequest}
    * @memberof PoliciesApiCreatePolicy
    */
    readonly createOrUpdatePolicyRequest: CreateOrUpdatePolicyRequest
}

/**
* Request parameters for deletePolicy operation in PoliciesApi.
* @export
* @interface PoliciesApiDeletePolicyRequest
*/
export interface PoliciesApiDeletePolicyRequest {
    /**
    * 
    * @type {string}
    * @memberof PoliciesApiDeletePolicy
    */
    readonly id: string
}

/**
* Request parameters for getPolicy operation in PoliciesApi.
* @export
* @interface PoliciesApiGetPolicyRequest
*/
export interface PoliciesApiGetPolicyRequest {
    /**
    * 
    * @type {string}
    * @memberof PoliciesApiGetPolicy
    */
    readonly id: string
}

/**
* Request parameters for reorderPoliciesPriority operation in PoliciesApi.
* @export
* @interface PoliciesApiReorderPoliciesPriorityRequest
*/
export interface PoliciesApiReorderPoliciesPriorityRequest {
    /**
    * 
    * @type {ReorderPoliciesPriorityRequest}
    * @memberof PoliciesApiReorderPoliciesPriority
    */
    readonly reorderPoliciesPriorityRequest: ReorderPoliciesPriorityRequest
}

/**
* Request parameters for resolvePolicy operation in PoliciesApi.
* @export
* @interface PoliciesApiResolvePolicyRequest
*/
export interface PoliciesApiResolvePolicyRequest {
    /**
    * 
    * @type {ResolvePolicyRequest}
    * @memberof PoliciesApiResolvePolicy
    */
    readonly resolvePolicyRequest: ResolvePolicyRequest

    /**
    * used internally in order to impersonate an account
    * @type {string}
    * @memberof PoliciesApiResolvePolicy
    */
    readonly accountId?: string
}

/**
* Request parameters for updatePolicy operation in PoliciesApi.
* @export
* @interface PoliciesApiUpdatePolicyRequest
*/
export interface PoliciesApiUpdatePolicyRequest {
    /**
    * 
    * @type {string}
    * @memberof PoliciesApiUpdatePolicy
    */
    readonly id: string

    /**
    * 
    * @type {CreateOrUpdatePolicyRequest}
    * @memberof PoliciesApiUpdatePolicy
    */
    readonly createOrUpdatePolicyRequest: CreateOrUpdatePolicyRequest
}

/**
* Request parameters for updatePolicyFields operation in PoliciesApi.
* @export
* @interface PoliciesApiUpdatePolicyFieldsRequest
*/
export interface PoliciesApiUpdatePolicyFieldsRequest {
    /**
    * 
    * @type {string}
    * @memberof PoliciesApiUpdatePolicyFields
    */
    readonly id: string

    /**
    * 
    * @type {UpdatePolicyFieldsRequest}
    * @memberof PoliciesApiUpdatePolicyFields
    */
    readonly updatePolicyFieldsRequest: UpdatePolicyFieldsRequest
}


    
        /**
* Get violation data.
* @summary Get violation data.
* @param params ViolationsApiGetViolationRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getViolationUrl = (params: ViolationsApiGetViolationRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('getViolation', 'id', params.id)
    const localVarPath = `/api/v1/violations/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get violation history.
* @summary Get violation history.
* @param params ViolationsApiGetViolationHistoryRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getViolationHistoryUrl = (params: ViolationsApiGetViolationHistoryRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('getViolationHistory', 'id', params.id)
    const localVarPath = `/api/v1/violations/:id/history`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get all the violations.
* @summary Get all the violations.
* @param params ViolationsApiGetViolationsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getViolationsUrl = (params: ViolationsApiGetViolationsRequest, baseUrl: string): string => {
    // verify required parameter 'pageSize' is not null or undefined
    assertParamExists('getViolations', 'pageSize', params.pageSize)
    // verify required parameter 'offset' is not null or undefined
    assertParamExists('getViolations', 'offset', params.offset)
    // verify required parameter 'impactGroupType' is not null or undefined
    assertParamExists('getViolations', 'impactGroupType', params.impactGroupType)
    const localVarPath = `/api/v1/violations`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.offset !== undefined) {
            localVarQueryParameter['offset'] = params.offset;
    }
    if (params.checkType) {
        localVarQueryParameter['checkType'] = params.checkType;
    }
    if (params.status) {
        localVarQueryParameter['status'] = params.status;
    }
    if (params.clusterName) {
        localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace) {
        localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.shortResourceNameSearchTerm !== undefined) {
            localVarQueryParameter['shortResourceNameSearchTerm'] = params.shortResourceNameSearchTerm;
    }
    if (params.shortResourceName) {
        localVarQueryParameter['shortResourceName'] = params.shortResourceName;
    }
    if (params.impactGroupId) {
        localVarQueryParameter['impactGroupId'] = params.impactGroupId;
    }
    if (params.impactGroupType) {
        localVarQueryParameter['impactGroupType'] = params.impactGroupType;
    }
    if (params.severity) {
        localVarQueryParameter['severity'] = params.severity;
    }
    if (params.komodorUid) {
        localVarQueryParameter['komodorUid'] = params.komodorUid;
    }
    if (params.resourceType) {
        localVarQueryParameter['resourceType'] = params.resourceType;
    }
    if (params.createdFromEpoch !== undefined) {
            localVarQueryParameter['createdFromEpoch'] = params.createdFromEpoch;
    }
    if (params.createdToEpoch !== undefined) {
            localVarQueryParameter['createdToEpoch'] = params.createdToEpoch;
    }
    if (params.violationId) {
        localVarQueryParameter['violationId'] = params.violationId;
    }
    if (params.filterSupportedCheckTypes !== undefined) {
            localVarQueryParameter['filterSupportedCheckTypes'] = params.filterSupportedCheckTypes;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get the number of violations, grouped by the countBy.
* @summary Get the number of violations, grouped by the countBy.
* @param params ViolationsApiGetViolationsFilterRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getViolationsFilterUrl = (params: ViolationsApiGetViolationsFilterRequest, baseUrl: string): string => {
    // verify required parameter 'countBy' is not null or undefined
    assertParamExists('getViolationsFilter', 'countBy', params.countBy)
    // verify required parameter 'postViolationsCountRequestBody' is not null or undefined
    assertParamExists('getViolationsFilter', 'postViolationsCountRequestBody', params.postViolationsCountRequestBody)
    const localVarPath = `/api/v1/violations/count/:countBy`
        .replace(`:${"countBy"}`, encodeURIComponent(String(params.countBy)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Post feedback on specific violation
* @summary Post feedback on specific violation
* @param params ViolationsApiPostViolationFeedbackRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const postViolationFeedbackUrl = (params: ViolationsApiPostViolationFeedbackRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('postViolationFeedback', 'id', params.id)
    // verify required parameter 'body' is not null or undefined
    assertParamExists('postViolationFeedback', 'body', params.body)
    const localVarPath = `/api/v1/violations/:id/feedback`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Update violation.
* @summary Update violation.
* @param params ViolationsApiUpdateViolationRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const updateViolationUrl = (params: ViolationsApiUpdateViolationRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('updateViolation', 'id', params.id)
    // verify required parameter 'body' is not null or undefined
    assertParamExists('updateViolation', 'body', params.body)
    const localVarPath = `/api/v1/violations/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for getViolation operation in ViolationsApi.
* @export
* @interface ViolationsApiGetViolationRequest
*/
export interface ViolationsApiGetViolationRequest {
    /**
    * 
    * @type {string}
    * @memberof ViolationsApiGetViolation
    */
    readonly id: string
}

/**
* Request parameters for getViolationHistory operation in ViolationsApi.
* @export
* @interface ViolationsApiGetViolationHistoryRequest
*/
export interface ViolationsApiGetViolationHistoryRequest {
    /**
    * 
    * @type {string}
    * @memberof ViolationsApiGetViolationHistory
    */
    readonly id: string
}

/**
* Request parameters for getViolations operation in ViolationsApi.
* @export
* @interface ViolationsApiGetViolationsRequest
*/
export interface ViolationsApiGetViolationsRequest {
    /**
    * 
    * @type {number}
    * @memberof ViolationsApiGetViolations
    */
    readonly pageSize: number

    /**
    * 
    * @type {number}
    * @memberof ViolationsApiGetViolations
    */
    readonly offset: number

    /**
    * 
    * @type {Array<ImpactGroupType>}
    * @memberof ViolationsApiGetViolations
    */
    readonly impactGroupType: Array<ImpactGroupType>

    /**
    * used internally in order to impersonate an account
    * @type {string}
    * @memberof ViolationsApiGetViolations
    */
    readonly accountId?: string

    /**
    * 
    * @type {Array<CheckType>}
    * @memberof ViolationsApiGetViolations
    */
    readonly checkType?: Array<CheckType>

    /**
    * 
    * @type {Array<ViolationStatus>}
    * @memberof ViolationsApiGetViolations
    */
    readonly status?: Array<ViolationStatus>

    /**
    * 
    * @type {Array<string>}
    * @memberof ViolationsApiGetViolations
    */
    readonly clusterName?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof ViolationsApiGetViolations
    */
    readonly namespace?: Array<string>

    /**
    * Use in order to search a service name with a \&quot;contains\&quot; approach
    * @type {string}
    * @memberof ViolationsApiGetViolations
    */
    readonly shortResourceNameSearchTerm?: string

    /**
    * Use in order to search a service names
    * @type {Array<string>}
    * @memberof ViolationsApiGetViolations
    */
    readonly shortResourceName?: Array<string>

    /**
    * 
    * @type {Array<ImpactGroupIdentifier>}
    * @memberof ViolationsApiGetViolations
    */
    readonly impactGroupId?: Array<ImpactGroupIdentifier>

    /**
    * 
    * @type {Array<Severity>}
    * @memberof ViolationsApiGetViolations
    */
    readonly severity?: Array<Severity>

    /**
    * 
    * @type {Array<string>}
    * @memberof ViolationsApiGetViolations
    */
    readonly komodorUid?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof ViolationsApiGetViolations
    */
    readonly resourceType?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof ViolationsApiGetViolations
    */
    readonly createdFromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof ViolationsApiGetViolations
    */
    readonly createdToEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof ViolationsApiGetViolations
    */
    readonly violationId?: Array<string>

    /**
    * Determines whether the service should use the supported check types feature flag. *This is an internal param with default to true*.
    * @type {boolean}
    * @memberof ViolationsApiGetViolations
    */
    readonly filterSupportedCheckTypes?: boolean
}

/**
* Request parameters for getViolationsFilter operation in ViolationsApi.
* @export
* @interface ViolationsApiGetViolationsFilterRequest
*/
export interface ViolationsApiGetViolationsFilterRequest {
    /**
    * 
    * @type {ViolationCountBy}
    * @memberof ViolationsApiGetViolationsFilter
    */
    readonly countBy: ViolationCountBy

    /**
    * 
    * @type {PostViolationsCountRequestBody}
    * @memberof ViolationsApiGetViolationsFilter
    */
    readonly postViolationsCountRequestBody: PostViolationsCountRequestBody

    /**
    * used internally in order to impersonate an account
    * @type {string}
    * @memberof ViolationsApiGetViolationsFilter
    */
    readonly accountId?: string

    /**
    * used internally in order to impersonate a user
    * @type {string}
    * @memberof ViolationsApiGetViolationsFilter
    */
    readonly userId?: string
}

/**
* Request parameters for postViolationFeedback operation in ViolationsApi.
* @export
* @interface ViolationsApiPostViolationFeedbackRequest
*/
export interface ViolationsApiPostViolationFeedbackRequest {
    /**
    * 
    * @type {string}
    * @memberof ViolationsApiPostViolationFeedback
    */
    readonly id: string

    /**
    * 
    * @type {ViolationFeedback}
    * @memberof ViolationsApiPostViolationFeedback
    */
    readonly body: ViolationFeedback
}

/**
* Request parameters for updateViolation operation in ViolationsApi.
* @export
* @interface ViolationsApiUpdateViolationRequest
*/
export interface ViolationsApiUpdateViolationRequest {
    /**
    * 
    * @type {string}
    * @memberof ViolationsApiUpdateViolation
    */
    readonly id: string

    /**
    * 
    * @type {BasicViolation}
    * @memberof ViolationsApiUpdateViolation
    */
    readonly body: BasicViolation
}


    
