import React, { useMemo } from "react";
import { capitalize } from "lodash";

import { useGetCertificateData } from "./useGetCertificateData";

import { certificateStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { SeverityColor } from "@/components/k8sAddons/types";

interface Props {
  uid: string;
  name: string;
}

export const Status: React.FC<Props> = ({ uid, name }) => {
  const { data } = useGetCertificateData(uid, name);

  const { status, color } = useMemo<{
    status: string;
    color: SeverityColor;
  }>(() => {
    if (!data?.data)
      return {
        status: "",
        color: "info",
      };
    const { status } = data.data.object.header;
    const color = certificateStatusToColor[status];

    return {
      status,
      color,
    };
  }, [data?.data]);

  return (
    <AddonStatusChip color={color} label={capitalize(status)} size={"medium"} />
  );
};
