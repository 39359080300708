import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

const StyledDisabledInput = styled.input`
  color: ${muiColors.gray[500]};
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]};
  background-color: ${muiColors.gray[50]};
`;

type DisabledInputProps = {
  label: string;
  value: string;
  helperText?: string;
};

export const DisabledInput: React.FC<DisabledInputProps> = ({
  value,
  label,
  helperText,
}) => {
  return (
    <>
      <Typography variant={"body2"}>{label}</Typography>
      <StyledDisabledInput disabled value={value} />
      <Typography variant={"body3"} color={"text.secondary"}>
        {helperText}
      </Typography>
    </>
  );
};
